@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border !scroll-smooth;
  }
  body {
    @apply bg-background text-foreground;
  }
  button {
    @apply active:outline-none;
  }
}

@layer utilities {
  .heading {
    @apply font-bold text-4xl md:text-5xl text-center;
  }

  .black-gradient {
    background: linear-gradient(90deg, #161a31 0%, #06091f 100%);
  }
}
.slick-arrow {
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  border-radius: 50%;
  color: white;
  width: 80px; /* increase the size */
  height: 80px; /* increase the size */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s; /* add transitions for hover effects */
}

.slick-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8); /* darker on hover */
  transform: scale(1.1); /* slight zoom on hover */
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Custom icons for the arrows */
.slick-prev:before, .slick-next:before {
  font-family: 'slick'; /* using slick's built-in font */
  font-size: 20px; /* increase icon size */
  line-height: 1;
  opacity: 1; /* ensure visibility */
  color: white; /* icon color */
}

.slick-prev:before {
  content: '←'; /* change to a left arrow */
}

.slick-next:before {
  content: '→'; /* change to a right arrow */
}

.element{
  transition: all 0.09s ease-in;
}



.hh{
  position: relative;
  text-shadow: 0 0 10px rgb(25, 118, 210);
  transition: all linear 0.3s;
  -webkit-text-stroke: 1px #1500ff;
}

.boxShadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 -4px 6px rgba(0, 0, 0, 0.1), 
              4px 0 6px rgba(0, 0, 0, 0.1), 
              -4px 0 6px rgba(0, 0, 0, 0.1);
}



@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}
